import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import Cookies from "js-cookie";

const initialState = {
    loginData: false,
    loading: false,
    user: false,
    token: false,
    error: false,
}

const BASE_URL = 'https://monitor.gonec-eda.ru';

export const loginRequest = createAsyncThunk(
    'login/loginRequest', 
    async (data, { rejectWithValue, dispatch }) => {
        try {
            let config = {
                headers: {
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json'
                }
            }
            const res = await axios.post(BASE_URL + `/api/v1/auth/login`, data, config);
            dispatch(setToken(res.data.token));
            dispatch(setUser(res.data.user));
        }   catch (error) {
            dispatch(setError(error.response.data.message));
        }
    }
);

export const LoginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setLoginData: (state, action) => {
            state.loginData = { ...state.loginData, [action.payload.key]: action.payload.val };
        },
        clearLoginData: (state, action) => {
            state.loginData = false;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        setToken: (state, action) => {
            Cookies.set("jwt", action.payload, { expires: 1 });
            state.token = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state, action) => { state.loading = true; }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state, action) => { state.loading = false; }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => { state.loading = false; }
            );
    }
});

export const { 
    setLoginData, 
    clearLoginData, 
    setUser, 
    setToken, 
    setError
} = LoginSlice.actions

export default LoginSlice.reducer