import React from 'react';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

export default function Maps() {

    const CustomIcon = (iconUrl, iconSize) => {
        return new L.Icon({
          iconUrl,
          iconSize,
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
        });
    };

    const coordinates = [
        { lat: 48.568340, lng: 39.304096, description: 'Sushi House', iconUrl: require('../img/shops/Sushi House.png'), iconSize: [66, 54] }, 
        { lat: 48.568495, lng: 39.306405, description: 'Фабрика Кофе', iconUrl: require('../img/shops/fabrika_cofe.png'), iconSize: [66, 54] }, 
        { lat: 48.541087, lng: 39.263852, description: 'Alibi', iconUrl: require('../img/shops/Alibi.png'), iconSize: [66, 54] }, 
        { lat: 48.543664, lng: 39.334495, description: 'Шашлык Маркет', iconUrl: require('../img/shops/shashlyk_market.png'), iconSize: [66, 54] }, 
        { lat: 48.573652, lng: 39.306468, description: 'Бир Хофф', iconUrl: require('../img/shops/beer_hoff.png'), iconSize: [66, 54] }, 
        { lat: 48.567410, lng: 39.387757, description: 'Шаурма Club', iconUrl: require('../img/shops/Шаурма Club.png'), iconSize: [66, 54] },
        { lat: 48.567863, lng: 39.306163, description: 'Два Гуся', iconUrl: require('../img/shops/dva_cusia.png'), iconSize: [66, 54] },
        { lat: 48.573652, lng: 39.306468, description: 'Marrakesh', iconUrl: require('../img/shops/marrakesh.png'), iconSize: [66, 54] },
        { lat: 48.569479, lng: 39.374497, description: 'Восток', iconUrl: require('../img/shops/vostok.png'), iconSize: [66, 54] },
        { lat: 48.559575, lng: 39.363466, description: 'Кавказский Двор', iconUrl: require('../img/shops/Кавказский Двор.png'), iconSize: [66, 54] },
        { lat: 48.548835, lng: 39.329761, description: 'Чоли', iconUrl: require('../img/shops/choli.png'), iconSize: [66, 54] },
        { lat: 48.568746, lng: 39.310385, description: 'Семь Морей', iconUrl: require('../img/shops/7-morey.png'), iconSize: [66, 54] }, 
        { lat: 48.548835, lng: 39.329761, description: 'Papa Grill', iconUrl: require('../img/shops/Papa Grill.png'), iconSize: [66, 54] }, 
        { lat: 48.568889, lng: 39.313367, description: 'Шавуха твоего города', iconUrl: require('../img/shops/shaukha_goroda.png'), iconSize: [66, 54] }, 
        { lat: 48.539989, lng: 39.259522, description: 'Шаурма на углях', iconUrl: require('../img/shops/shaurma_na_uglakh.png'), iconSize: [66, 54] }, 
        { lat: 48.568680, lng: 39.329761, description: 'Taxi 1', iconUrl: require('../img/cars/1-min.png'), iconSize: [24, 52] },
        { lat: 48.553314, lng: 39.323752, description: 'Taxi 2', iconUrl: require('../img/cars/2-min.png'), iconSize: [24, 52]},
        { lat: 48.559575, lng: 39.263852, description: 'Taxi 5', iconUrl: require('../img/cars/5-min.png'), iconSize: [24, 52]},
        { lat: 48.541087, lng: 39.367832, description: 'Taxi 7', iconUrl: require('../img/cars/7-min.png'), iconSize: [24, 52]},
    ];


    return (
        <MapContainer center={[48.5652, 39.3172]} zoom={13} style={{ height: '100vh', width: '100%', zIndex: '1' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
             {coordinates && coordinates.map((coord, index) => (
                <Marker key={index} position={[coord.lat, coord.lng]} icon={CustomIcon(coord.iconUrl, coord.iconSize)}>
                    <Popup>{coord.description}</Popup>
                </Marker>
            ))}
        </MapContainer>
    );
};   