import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

const initialState = {
    codeAuth: false,
    tokenAuth: false,
    starlineUser: false,
    authSlid: false,
    cookies: false,
    coordinates: false,
    loading: false,
};

const AUTH_URL = 'https://id.starline.ru';
const STARLINE_URL = 'https://developer.starline.ru';

var md5 = require('md5');
var sha1 = require('js-sha1');

const logData = {
    appId: '25591',
    secret: 'EInx8W09HKu7YSIzGWAvvbHRXYak2xMj',
    login: 'Kunmark950@gmail.com',
    pass: 'GonecEda24'
};

export const getCode = createAsyncThunk(
    'maps/getCode', async (data, { rejectWithValue, dispatch }) => {
        try {
            let secretMD5 = md5(logData.secret);
            const res = await axios.get(AUTH_URL + `/apiV3/application/getCode?appId=${logData.appId}&secret=${secretMD5}`);
            dispatch(setCodeAuth(res.desc.code))
            console.log("🟢", res);
        }   catch (error) {
            const message = (error.response.data.message) || error.toString();
            console.log("❌", message);
            return rejectWithValue(message);
        }
    }
);

export const getToken = createAsyncThunk(
    'maps/getToken', async (data, { rejectWithValue, dispatch }) => {
        try {
            let secretCodeMD5 = md5(`${logData.secret}` + `${data}`);
            const res = await axios.get(AUTH_URL + `/apiV3/application/getToken?appId=${logData.appId}&secret=${secretCodeMD5}`);
            dispatch(setTokenAuth(res.desc.token))
            console.log("🟢", res);
        }   catch (error) {
            const message = (error.response.data.message) || error.toString();
            console.log("❌", message);
            return rejectWithValue(message);
        }
    }
);

export const userLogin = createAsyncThunk(
    'maps/userLogin', async (data, { rejectWithValue, dispatch }) => {
        try {
            let passSha1 = sha1(logData.pass);
            let dataUser = {
                pass: passSha1,
                login: logData.login
            };
            let config = {
                headers: { 
                    'token': data, 
                    'Content-Type': 'application/json'
                },
            }
            const res = await axios.post(AUTH_URL + `/apiV3/user/login`, dataUser, config);
            dispatch(setStarlineUser(res.desc))
            console.log("🟢", res);
        }   catch (error) {
            const message = (error.response.data.message) || error.toString();
            console.log("❌", message);
            return rejectWithValue(message);
        }
    }
);

export const starlineLogin = createAsyncThunk(
    'maps/starlineLogin', async (data, { rejectWithValue, dispatch }) => {
        try {
            let token = {
                'slid_token' : data
            }
            const res = await axios.post(STARLINE_URL + `/json/v2/auth.slid`, token);
            dispatch(setCookie(res.headers['Set-Cookie']))
            dispatch(setAuthSlid(res))
            console.log("🟢", res);
        }   catch (error) {
            const message = (error.response.data.message) || error.toString();
            console.log("❌", message);
            return rejectWithValue(message);
        }
    }
);

export const getCoordinates = createAsyncThunk(
    'maps/getCoordinates', async (data, { rejectWithValue, dispatch }) => {
        try {
            let config = {
                headers: { 
                    'Cookie': `slnet=${data.cookies}`
                  }
            }
            const res = await axios.post(STARLINE_URL + `/json/v2/user/${data.user_id}/user_info`, config);
            dispatch(setCoordinates(res.devices))
            console.log("🟢", res);
        }   catch (error) {
            const message = (error.response.data.message) || error.toString();
            console.log("❌", message);
            return rejectWithValue(message);
        }
    }
);

export const MapsSlice = createSlice({
    name: 'maps',
    initialState,
    reducers: {
        setCodeAuth: (state, action) => {
            state.codeAuth = action.payload;
        },
        setTokenAuth: (state, action) => {
            state.tokenAuth = action.payload;
        },
        setStarlineUser: (state, action) => {
            state.starlineUser = action.payload;
        },
        setAuthSlid: (state, action) => {
            state.authSlid = action.payload;
        },
        setCookie: (state, action) => {
            state.cookies = action.payload;
        },
        setCoordinates: (state, action) => {
            state.coordinates = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state, action) => { state.loading = true; }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state, action) => { state.loading = false; }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.loading = false;
                }
            );
    }
});

export const { 
    setCodeAuth, 
    setTokenAuth, 
    setStarlineUser, 
    setAuthSlid, 
    setCookie, 
    setCoordinates 
} = MapsSlice.actions

export default MapsSlice.reducer