import React, { useState, useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ru } from 'date-fns/locale/ru';
import moment from 'moment';
import 'moment/locale/ru';
import { isEmpty } from 'lodash';

import Sidebar from '../../components/Sidebar';
import Loader from '../../components/Loader';

import { getUsers, getUserStat } from '../../features/AdminSlice';

registerLocale('ru', ru);
moment().locale('ru');

export default function UserPage() {

    const dispatch = useDispatch();
    const admin = useSelector((state) => state.admin);

    const [activeDate, setActiveDate] = useState('today');
    const [modalShow, setModalShow] = useState(false);
    const [nickName, setNickName] = useState(false);
    const [openDatePicker, setOpenDatePicker] = useState(true);
    const [startDate, setStartDate] = useState(new Date());

    useEffect(() => {
        dispatch(getUsers());
    }, []);

    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <button 
            type="button" 
            className='btn btn-grd-warning btn-end text-white' 
            onClick={onClick}
            ref={ref}
        >
            {value}
        </button>
    ));

    const getColor = (status) => {
        switch (status) {
          case "CREATED":
            return 'info'
          case "PENDING":
            return 'warning'
          case "SUCCESS":
            return 'success'    
          case "CANCELED":
            return 'danger'
          case "MANUAL":
            return 'primary'
        }
    };

    const getStatus = (status) => {
        switch (status) {
          case "CREATED":
            return "Новый"
          case "PENDING":
            return "Принят курьером"
          case "SUCCESS":
            return "Завершен"
          case "CANCELED":
            return "Заказ отменен"
          case "MANUAL":
            return "Ручной чек"
        }

    };

    return (
        <>
            <Sidebar/>
            <main className="main-wrapper">
                <div className="main-content">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item active">Статистика по пользоватилям</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {admin.loading === true ? (
                        <div className='col-12 text-center mt-5'>
                            <Loader/>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-12 d-flex align-items-stretch">
                                <div className="card w-100 rounded-4">
                                    <div className="card-body">
                                        <div className="d-flex align-items-start justify-content-between m-3 mb-5 w-100">
                                            <div className="row w-100">
                                                <div className='col-12 col-md-6'>
                                                    <h5 className="mb-0">
                                                        Статистика за {activeDate === 'today' ? 'сегодня' : `${moment(startDate).format('DD.MM.YYYY')}`}
                                                    </h5>
                                                </div>
                                                <div className='col-12 col-md-6 text-end pe-5 mt-3 mt-md-0'>
                                                    <div className="btn-group" role="group" >
                                                        <button 
                                                            type="button" 
                                                            className={`btn ${activeDate === 'today' ? 'btn-grd btn-grd-warning' : 'btn-outline-warning'}`}
                                                            onClick={() => {
                                                                setActiveDate('today');
                                                                setStartDate(new Date);
                                                                setOpenDatePicker(true);
                                                            }}
                                                        >
                                                            Сегодня
                                                        </button>
                                                        {activeDate === 'today' ? (
                                                            <button 
                                                                type="button" 
                                                                className={`btn ${activeDate === 'date' ? 'btn-grd btn-grd-warning' : 'btn-outline-warning'}`} 
                                                                onClick={() => setActiveDate('date')}
                                                            >
                                                                Выбрать дату
                                                            </button>
                                                        ) : (
                                                            openDatePicker ? (
                                                                <DatePicker
                                                                    selected={startDate}
                                                                    onChange={(date) => {setStartDate(date); setOpenDatePicker(false)}}
                                                                    customInput={<CustomInput />}
                                                                    locale="ru"
                                                                    dateFormat="dd/MM/yyyy"
                                                                    open={true}
                                                                />
                                                            ) : (
                                                                <DatePicker
                                                                    selected={startDate}
                                                                    onChange={(date) => setStartDate(date)}
                                                                    customInput={<CustomInput />}
                                                                    locale="ru"
                                                                    dateFormat="dd/MM/yyyy"
                                                                />
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            {admin.userData ? (
                                                <table className="table align-middle">
                                                    <thead>
                                                        <tr>
                                                            <th style={{width: '9%'}}>ID</th>
                                                            <th style={{width: '20%'}}>Name</th>
                                                            <th style={{width: '20%'}}>Nickname</th>
                                                            <th style={{width: '20%'}}>Статус</th>
                                                            <th style={{width: '30%'}}>Статистика</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {admin.userData.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{item.id}</td>
                                                                <td>{item.metadata.name}</td>
                                                                <td>
                                                                    <div className="d-flex align-items-center gap-3">
                                                                        <p className="mb-0">{item.nickname}</p>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {item.online === true ? (
                                                                        <span className="badge bg-grd-success">Online</span>
                                                                    ) : (
                                                                        <span className="badge bg-grd-royal">Offline</span>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <button 
                                                                        type="button" 
                                                                        className="btn btn-inverse-success px-md-5 px-0"
                                                                        onClick={() => {
                                                                            dispatch(getUserStat({'date': moment(startDate).format('YYYY-MM-DD'), 'userId': item.id}));
                                                                            setModalShow(true);
                                                                            setNickName(item.metadata.name);
                                                                        }}
                                                                    >
                                                                        Посмотреть статистику
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <div className='row m-0'>
                                                    <div className='col-12 text-center justify-content-center my-5'>
                                                        <button className="btn btn-outline-warning px-5 py-4 no-hover" type="button" disabled="" style={{color: '#fff'}}> 
                                                            Пользоватилей нету...
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </main>
            <div className={`modal fade ${modalShow === true && 'show'}`} style={{display: `${modalShow === true ? 'block' : 'none'}`}} >
                <div className="modal-dialog modal-dialog-centered w-50" style={{maxWidth: 'none'}}>
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0 py-2">
                            <h5 className="modal-title m-2 mt-3">
                                Отчет по курьеру 
                                <strong className='text-danger'> {nickName} </strong>
                                за {moment(startDate).format('DD.MM.YYYY')} 
                            </h5>
                            <a href="#" className="primaery-menu-close" onClick={() => {setModalShow(false)}}>
                                <i className="material-icons-outlined">close</i>
                            </a>
                        </div>
                        <div className="modal-body p-md-4 p-2">
                            {admin.loading === true ? (
                                <div className='col-12 text-center mt-5'>
                                    <Loader/>
                                </div>
                            ) : (
                                !isEmpty(admin.userStat.orders) ? (
                                    <>
                                        <div className='table-responsive'>
                                            <table className="table align-middle">
                                                <thead>
                                                    <tr>
                                                        <th style={{width: '10%'}}>ID</th>
                                                        <th style={{width: '25%'}}>Заведение</th>
                                                        <th style={{width: '15%'}}>Сумма</th>
                                                        <th style={{width: '20%'}}>Тип</th>
                                                        <th style={{width: '30%'}}>Статус</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {admin.userStat.orders.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.id}</td>
                                                            <td>
                                                                <div className="d-flex align-items-center gap-3">
                                                                    <p className="mb-0">{item.store}</p>
                                                                </div>
                                                            </td>
                                                            <td>{item.total} ₽</td>
                                                            <td>
                                                                {item.pay_type === 'cash' ? (
                                                                    <span className="text-success">Наличные</span>
                                                                ) : (
                                                                    <span className="text-danger">Безналичные</span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <span className={`badge bg-grd-${getColor(item.status)}`} style={{fontSize: '14px'}}>
                                                                    {getStatus(item.status)}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="full-info mx-md-2 mx-4 mt-5">
                                            <div className="info-list d-flex flex-column gap-3">
                                                <div className="info-list-item d-flex align-items-center gap-3">
                                                    <img src={require('../../img/icons/clipboard.png')} className='' width={20} alt="icon"/>
                                                    <h5 className="mb-0 media-fs-17">
                                                        Вcего заказов:
                                                        <strong className='text-info'> {admin.userStat.orders.length} шт.</strong>
                                                    </h5>
                                                </div>
                                                <div className="info-list-item d-flex align-items-center gap-3">
                                                    <img src={require('../../img/icons/money.png')} className='' width={20} alt="icon"/>
                                                    <h5 className="mb-0 media-fs-17">
                                                        Вcего наличкой: 
                                                        <strong className='text-success'> {admin.userStat.totals.total_cash} ₽</strong>
                                                    </h5>
                                                </div>
                                                <div className="info-list-item d-flex align-items-center gap-3">
                                                    <img src={require('../../img/icons/credit-card.png')} className='' width={20} alt="icon"/>
                                                    <h5 className="mb-0 media-fs-17">
                                                        Вcего безналичкой: 
                                                        <strong className='text-danger'> {admin.userStat.totals.total_card} ₽</strong>
                                                    </h5>
                                                </div>
                                                <div className="info-list-item d-flex align-items-center gap-3">
                                                    <img src={require('../../img/icons/wallet.png')} className='' width={20} alt="icon"/>
                                                    <h5 className="mb-0 media-fs-17">
                                                        Вcего: 
                                                        <strong className='text-warning'> {admin.userStat.totals.total} ₽</strong>
                                                    </h5>
                                                </div>
                                                <div className="alert alert-border-warning alert-dismissible fade show me-md-5 me-0 mb-0 mt-3 pe-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="font-35 text-warning d-none d-md-block">
                                                            <span className="material-icons-outlined fs-2">info</span>
                                                        </div>
                                                        <div className="ms-3">
                                                            <h6 className="mb-0 text-warning media-fs-14">Суммы выше - указаны по заказам со статусами – «Завершен» и «Ручной чек».</h6>
                                                            <div className="media-fs-12 mt-2">Заказы с другими статусами («Новый», «Принят курьером», «Заказ отменен») в окончательные суммы не входят!</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className='row'>
                                        <div className='col-12 text-center justify-content-center mb-5'>
                                            <button className="btn btn-outline-warning no-hover text-warning p-3" type="button" disabled=""> 
                                                <strong>У курьера {nickName} нету заказов за {moment(startDate).format('DD.MM.YYYY')}</strong>
                                            </button>
                                        </div>
                                    </div>
                                )
                            )}   
                        </div>
                        <div className="modal-footer border-top-0">
                            <button 
                                type="button" 
                                className="btn btn-grd-warning text-white" 
                                onClick={() => {setModalShow(false)}}
                            >
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};    