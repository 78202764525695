import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFoundPage() {
    return (
        <>
            <div className="pace  pace-inactive">
                <div className="pace-progress" data-progress-text="100%" data-progress="99" style={{transform: 'translate3d(100%, 0px, 0px)'}}>
                    <div className="pace-progress-inner"></div>
                </div>
                <div className="pace-activity"></div>
            </div>
            <div className="auth-basic-wrapper d-flex align-items-center justify-content-center">
                <div className="container-fluid my-5 my-lg-0">
                    <div className="row">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4 mx-auto">
                            <div className="card rounded-4 mb-0 border-top border-4 border-primary border-gradient-1">
                                <div className="card-body p-5 text-center">
                                    <img src={require('../../img/404.jpg')} className="rounded-circle" width="400" alt="Page 404"/>
                                    <h4 className="fw-bold text-danger">Страницу не найдено!</h4>
                                    <div className="separator section-padding">
                                        <div className="line"></div>
                                            <p className="mb-0 fw-bold">404</p>
                                        <div className="line"></div>
                                    </div>
                                    <div className="d-flex gap-3 justify-content-center mt-4">
                                        <Link to={'/'}>
                                            <button type="button" className="btn btn-grd btn-grd-warning px-5">
                                                Вернутся на главную
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};    