import React from 'react';

export default function Sidebar() {

    return (
        <button className="btn btn-grd-warning" type="button" disabled="" style={{color: '#fff'}}> 
            <span className="spinner-grow spinner-grow-sm me-2"></span>
            Загрузка...
        </button>
    );
};   