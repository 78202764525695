import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { isEmpty } from 'lodash';

import Loader from '../../components/Loader';
import Alert from '../../components/Alert';

import { setLoginData, loginRequest } from '../../features/LoginSlice';

export default function LoginPage() {

    const dispatch = useDispatch();

    const [showPass, setShowPass] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);

    const login = useSelector((state) => state.login);

    const loginFunction = (e) => {
        if (e) { e.preventDefault(); }
        if (!isEmpty(login.loginData.nickname)) {
            setInvalidEmail(false);
        } else {
            setInvalidEmail(true);
            return;
        }
        if (!isEmpty(login.loginData.password)) {
            setInvalidPassword(false);
        } else {
            setInvalidPassword(true);
            return;
        }
        dispatch(loginRequest(login.loginData));
    };

    return (
        <>
            {login.token && login.user && <Navigate push to={{pathname: '/'}}/>}
            {login.error && <Alert title='Ошибка!' text={login.error}/>}
            <div className="section-authentication-cover">
                <div className="row g-0">
                    <div className="col-12 col-xl-7 col-xxl-8 auth-cover-left align-items-center justify-content-center d-none d-xl-flex border-end bg-transparent">
                        <div className="card rounded-0 mb-0 border-0 shadow-none bg-transparent bg-none">
                            <div className="card-body">
                                <img src={require("../../img/ivan-kon.png")} className="img-fluid auth-img-cover-login login-img" width="1150" alt="img Gonec"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-5 col-xxl-4 auth-cover-right align-items-center justify-content-center border-top border-4 border-primary border-gradient-1">
                        <div className="card rounded-0 m-3 mb-0 border-0 shadow-none bg-none">
                            <div className="card-body p-sm-5">
                                <img src={require("../../img/logo/logo_new.png")} className="mb-4" width="200" alt="Logo Gonec"/>
                                <h4 className="fw-bold">Вход</h4>
                                <p className="mb-0">Ввойдите для начала роботы</p>
                                <div className="form-body mt-4">
                                    <form className="row g-3" onSubmit={loginFunction}>
                                        <div className="col-12">
                                            {invalidEmail ? (
                                                <label htmlFor="inputEmailAddress" className="form-label text-danger">Введите nickname</label>
                                            ) : (
                                                <label htmlFor="inputEmailAddress" className="form-label">Nickname</label>
                                            )}
                                            <input 
                                                type="text" 
                                                name='nickname'
                                                className={`form-control ${invalidEmail ? 'border-danger' : 'border-warning'}`}
                                                id="inputEmailAddress" 
                                                placeholder="gonec@eda.com"
                                                onChange={(e) => dispatch(setLoginData({key: e.target.name, val: e.target.value}))}
                                            />
                                        </div>
                                        <div className="col-12">
                                            {invalidPassword ? (
                                                <label htmlFor="inputChoosePassword" className="form-label text-danger">Введите пароль</label>
                                            ) : (
                                                <label htmlFor="inputChoosePassword" className="form-label">Пароль</label>
                                            )}
                                            <div className="input-group">
                                                <input 
                                                    type={showPass ? 'text' : 'password'}
                                                    name='password'
                                                    className={`form-control ${invalidPassword ? 'border-danger' : 'border-warning'}`}
                                                    id="inputChoosePassword"   
                                                    placeholder="*********"
                                                    onChange={(e) => dispatch(setLoginData({key: e.target.name, val: e.target.value}))}
                                                />
                                                <span className="input-group-text bg-transparent border-warning" onClick={() => setShowPass(!showPass)}>
                                                    {showPass === true ? (
                                                        <i className="bi bi-eye-fill text-warning"></i>
                                                    ) : (
                                                        <i className="bi bi-eye-slash-fill"></i>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="d-grid">
                                                {login === true ? (
                                                    <Loader/>
                                                ) : (
                                                    <button type="submit" className="btn btn-grd-warning mt-3 text-white">Войти</button>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};    