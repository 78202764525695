import React from 'react';
import { Provider } from 'react-redux';
import {  BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';

import Cookies from "js-cookie";
import { isEmpty } from 'lodash';

import './App.css';
import { store } from './store/store';

import LoginPage from './containers/login/LoginPage';
import NotFoundPage from './containers/login/NotFoundPage';

import OrdersPage from './containers/orders/OrdersPage';

import StartPage from './containers/dashboard/StartPage';
import ShopsPage from './containers/dashboard/ShopsPage';
import UserPage from './containers/dashboard/UserPage';

import MapsPage from './containers/maps/MapsPage';

function App() {

  const PrivateWrapper =  ({ children }) => {
    
    if ( !isEmpty(Cookies.get("jwt")) ) {
      var user = true;
    } else {
      user = false;
    }
    return user ? <Outlet /> : <Navigate to="/login" />;
  };

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route element={<PrivateWrapper />}>
            <Route exact path="/" element={<StartPage/>} />
            <Route exact path="/orders" element={<OrdersPage/>} />
            <Route exact path="/shops" element={<ShopsPage/>} />
            <Route exact path="/user" element={<UserPage/>} />
            <Route exact path="/map" element={<MapsPage/>} />
          </Route>
          <Route exact path="/login" element={<LoginPage/>} />
          <Route path="*" element={<NotFoundPage/>} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
