import React from 'react';

export default function Alert({ title, text }) {

    return (
        <div className='w-50 ms-auto '>
            <div className="alert alert-danger border-0 bg-grd-danger alert-dismissible fade show alert-style">
                <div className="d-flex align-items-center">
                    <div className="font-35 text-white">
                        <span className="material-icons-outlined fs-2">report_gmailerrorred</span>
                    </div>
                    <div className="ms-3">
                        <h6 className="mb-0 text-white">{title}</h6>
                        <div className="text-white">{text}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};  