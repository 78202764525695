import { configureStore } from '@reduxjs/toolkit';

import AdminSlice from '../features/AdminSlice';
import LoginSlice from '../features/LoginSlice';
import MapsSlice from '../features/MapsSlice';
import OrdersSlice from '../features/OrdersSlice';

export const store = configureStore({
    reducer: {
        admin : AdminSlice,
        login : LoginSlice,
        maps : MapsSlice,
        orders : OrdersSlice,
    }
})