import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import 'moment/locale/ru';

import Sidebar from '../../components/Sidebar';
import Loader from '../../components/Loader';

import { getActiveShops, getStatDay, getStatMonth, getTopUsers, getTopShops } from '../../features/AdminSlice';
import { toNumber } from 'lodash';

moment().locale('ru');

export default function StartPage() {

    const dispatch = useDispatch();
    const admin = useSelector((state) => state.admin);

    useEffect(() => {
        dispatch(getActiveShops());
        dispatch(getStatDay());
        dispatch(getStatMonth());
        dispatch(getTopUsers());
        dispatch(getTopShops());
    }, []);

    const chartData = {
        options: {
            chart: { type: "donut" },
            legend: { show: false },
            dataLabels: { enabled: false },
            tooltip: { enabled: false },
            fill: { 
                type: 'gradient',
                colors: [
                    '#ffc107', //primary
                    '#fc185a', //danger
                ],
            },
            states: {
                hover: { filter: { type: "lighten", value: 0.2 } },
                active: { filter: { type: "none", value: 0 } }
            },
          stroke: { width: 0 },
          plotOptions: {
            pie: {
              expandOnClick: false,
              donut: {
                size: "85%",
                labels: {
                  show: true,
                  name: { show: false },
                  total: {
                    show: false,
                    showAlways: true,
                  }
                }
              }
            }
          }
        }
    };

    const chartData2 = {
        options: {
            chart: { type: "donut" },
            legend: { show: false },
            dataLabels: { enabled: false },
            tooltip: { enabled: false },
            fill: { 
                type: 'gradient',
                colors: [
                    '#02c27a', //success
                    '#0d6efd', //info
                ],
            },
            states: {
                hover: { filter: { type: "lighten", value: 0.2 } },
                active: { filter: { type: "none", value: 0 } }
            },
          stroke: { width: 0 },
          plotOptions: {
            pie: {
              expandOnClick: false,
              donut: {
                size: "85%",
                labels: {
                  show: true,
                  name: { show: false },
                  total: {
                    show: false,
                    showAlways: true,
                  }
                }
              }
            }
          }
        }
    };

    const GetPercentage = (total, number) => {
        let percentage = (number * 100) / total
        if (percentage > 0) {
            return toNumber(percentage.toFixed());
        } else {
            return 0;
        }
    }  

    let notActiveShops = [
        {
            store_name: 'Aquarius',
            id: '36'
        },
        {
            store_name: 'Вкусно быстро',
            id: '44'
        },
        {
            store_name: 'Barbecue',
            id: '25'
        },
        {
            store_name: 'Стейк бар Бункер',
            id: '46'
        },
        {
            store_name: 'Chelsea',
            id: '45'
        },
        {
            store_name: 'Мичиган',
            id: '38'
        },
        {
            store_name: 'Джрвеж',
            id: '43'
        },
        {
            store_name: 'Пиросмани',
            id: '40'
        },
        {
            store_name: 'Street Food 60',
            id: '42'
        },
        {
            store_name: 'Золотой теленок',
            id: '39'
        },
    ];

    return (
        <>
            <Sidebar/>
            <main className="main-wrapper">
                <div className="main-content">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="breadcrumb-title pe-3">Главная</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {moment().format('DD.MM.YYYY')}
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-7" >
                            <div className="row">
                                <div className='col-12'>
                                    <div className="card rounded-4 w-100">
                                        <div className="card-body">
                                            <div className="">
                                                <div className="d-flex align-items-center gap-2 mb-2">
                                                    <h5 className="mb-0">Добро пожаловать! 🎉</h5>
                                                </div>
                                                <p className="mb-2 mb-md-4 media-fs-17">ГОНЕЦ - доставка еды, которой можно доверять!</p>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <Link to={'/orders'}>
                                                        <button className="btn btn-grd btn-grd-primary rounded-5 border-0 px-4 media-fs-14">
                                                            Перейти к заказам
                                                        </button>
                                                    </Link>
                                                    <img src={require('../../img/logo/logo_gonec.png')} width="100" alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {admin.statDay && admin.statMonth ? (
                                    <>
                                        <div className="col-12 col-md-6 d-flex align-items-stretch">
                                            <div className="card w-100 overflow-hidden rounded-4">
                                                <div className="card-body position-relative p-4">
                                                    <div className="d-flex align-items-center gap-5 ">
                                                        <div className="w-100">
                                                            <h5 className="mb-4 fw-semibold d-flex align-content-center">
                                                                Статистика за сегодня
                                                            </h5>
                                                            <p className="mb-3">
                                                                Успешные заказы
                                                                <span className="float-end">{admin.statDay.totals.total} ₽</span>
                                                            </p>
                                                            <div className="progress mb-0" style={{height:'5px'}}>
                                                                <div className="progress-bar bg-grd-warning" role="progressbar" style={{width: `${GetPercentage(admin.statMonth.totals.total, admin.statDay.totals.total)}%`}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="d-flex flex-column gap-3">
                                                                <div className="mt-3">
                                                                    <p className="mb-3">
                                                                        Без финального статуса 
                                                                        <span className="float-end">{admin.statDay.not_finalized.total} ₽</span>
                                                                    </p>
                                                                    <div className="progress" style={{height: '5px'}}>
                                                                        <div className="progress-bar bg-grd-royal" style={{width: `${GetPercentage(admin.statDay.totals.total, admin.statDay.not_finalized.total)}%`}}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 d-flex align-items-stretch">
                                            <div className="card w-100 overflow-hidden rounded-4">
                                                <div className="card-body position-relative p-4">
                                                    <div className="d-flex align-items-center gap-5 ">
                                                        <div className="w-100">
                                                            <h5 className="mb-4 fw-semibold d-flex align-content-center">
                                                                Статистика за месяц
                                                            </h5>
                                                            <p className="mb-3">
                                                                Успешные заказы
                                                                <span className="float-end">{admin.statMonth.totals.total} ₽</span>
                                                            </p>
                                                            <div className="progress mb-0" style={{height:'5px'}}>
                                                                <div className="progress-bar bg-grd-warning" role="progressbar" style={{width: "100%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <div className="d-flex flex-column gap-3">
                                                                <div className="mt-3">
                                                                    <p className="mb-3">
                                                                        Без финального статуса 
                                                                        <span className="float-end">{admin.statMonth.not_finalized.total} ₽</span>
                                                                    </p>
                                                                    <div className="progress" style={{height: '5px'}}>
                                                                        <div className="progress-bar bg-grd-royal" style={{width: `${GetPercentage(admin.statMonth.totals.total, admin.statMonth.not_finalized.total)}%`}}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className='col-12 text-center mb-4'>
                                        <Loader/>
                                    </div>
                                )}
                                <div className='col-12'>
                                    <div className="alert alert-br alert-warning border-0 bg-grd-warning alert-dismissible fade show mb-4 pe-1 pe-md-3">
                                        <div className="d-flex align-items-center">
                                            <div className="font-35 text-white d-none d-md-block">
                                                <span className="material-icons-outlined fs-2">check_circle</span>
                                            </div>
                                            <div className="ms-1 ms-md-3">
                                                <div className="text-white">
                                                    Сумма 
                                                    <span style={{fontSize: '16px', fontWeight: '600'}}> «Успешныe заказы» </span>
                                                    – это сумма заказов, получивших финальный статус ( «Завершен» / «Ручной чек» ) !
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className={`alert alert-br alert-dark border-0 bg-grd-royal alert-dismissible fade show mb-4 pe-1 pe-md-3`}>
                                        <div className="d-flex align-items-center">
                                            <div className="font-35 text-white d-none d-md-block">
                                                <span className="material-icons-outlined fs-2">info</span>
                                            </div>
                                            <div className="ms-1 ms-md-3">
                                                <div className="text-white">
                                                    Сумма 
                                                    <span style={{fontSize: '16px', fontWeight: '600'}}> «Без финального статуса» </span>
                                                    – это сумма заказов, не получивших финального статуса ( «Завершен» / «Заказ отменен» ) !
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {admin.statDay && admin.statMonth ? (
                                    <>
                                        <div className="col-12 col-md-6 d-flex align-items-stretch">
                                            <div className="card w-100 rounded-4 mb-0">
                                                <div className="card-body p-4">
                                                    <div className="d-flex flex-column gap-3">
                                                        <div className="d-flex align-items-start justify-content-between">
                                                            <div className="text-center">
                                                                <h5 className="mb-0">Статистика по типу оплаты за сегодня</h5>
                                                            </div>
                                                        </div>
                                                        <div className="position-relative my-1 my-md-3">
                                                            <div className="piechart-legend">
                                                                <h4 className="mb-1">100%</h4>
                                                                <h6 className="mb-0">Заказы</h6>
                                                            </div>
                                                            <ReactApexChart
                                                                options={chartData2.options}
                                                                series={[
                                                                    GetPercentage(admin.statDay.totals.total, admin.statDay.totals.total_cash),
                                                                    GetPercentage(admin.statDay.totals.total, admin.statDay.totals.total_card),
                                                                ]}
                                                                colors={chartData2.options.fill.colors}
                                                                type="donut"
                                                                width={250}
                                                                className='d-flex justify-content-center'
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column gap-3">
                                                            <div className=" mt-3">
                                                                <p className="mb-3">
                                                                    <img src={require('../../img/icons/money.png')} className='me-2' width={20} alt="icon"/>
                                                                    Наличные 
                                                                    ({GetPercentage(admin.statDay.totals.total, admin.statDay.totals.total_cash)}%)
                                                                    <span className="float-end">
                                                                        {admin.statDay.totals.total_cash} ₽
                                                                    </span>
                                                                </p>
                                                                <div className="progress" style={{height: '7px'}}>
                                                                    <div className="progress-bar bg-success" style={{width: `${GetPercentage(admin.statDay.totals.total, admin.statDay.totals.total_cash)}%`}}></div>
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                <p className="mb-3">
                                                                    <img src={require('../../img/icons/credit-card.png')} className='me-2' width={20} alt="icon"/>
                                                                    Безналичные 
                                                                    ({GetPercentage(admin.statDay.totals.total, admin.statDay.totals.total_card)}%)
                                                                    <span className="float-end">
                                                                        {admin.statDay.totals.total_card} ₽
                                                                    </span>
                                                                </p>
                                                                <div className="progress" style={{height: '7px'}}>
                                                                    <div className="progress-bar bg-info" style={{width: `${GetPercentage(admin.statDay.totals.total, admin.statDay.totals.total_card)}%`}}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                                            <div className="card w-100 rounded-4 mb-0">
                                                <div className="card-body p-4">
                                                    <div className="d-flex flex-column gap-3">
                                                        <div className="d-flex align-items-start justify-content-between">
                                                            <div className="text-center">
                                                                <h5 className="mb-0">Статистика по типу оплаты за месяц</h5>
                                                            </div>
                                                        </div>
                                                        <div className="position-relative my-1 my-md-3">
                                                            <div className="piechart-legend">
                                                                <h4 className="mb-1">100%</h4>
                                                                <h6 className="mb-0">Заказы</h6>
                                                            </div>
                                                            <ReactApexChart
                                                                options={chartData.options}
                                                                series={[
                                                                    GetPercentage(admin.statMonth.totals.total, admin.statMonth.totals.total_cash),
                                                                    GetPercentage(admin.statMonth.totals.total, admin.statMonth.totals.total_card),
                                                                ]}
                                                                colors={chartData.options.fill.colors}
                                                                type="donut"
                                                                width={250}
                                                                className='d-flex justify-content-center'
                                                            />
                                                        </div>
                                                        <div className="d-flex flex-column gap-3">
                                                            <div className=" mt-3">
                                                                <p className="mb-3">
                                                                    <img src={require('../../img/icons/money.png')} className='me-2' width={20} alt="icon"/>
                                                                    Наличные 
                                                                    ({GetPercentage(admin.statMonth.totals.total, admin.statMonth.totals.total_cash)}%)
                                                                    <span className="float-end">
                                                                        {admin.statMonth.totals.total_cash} ₽
                                                                    </span>
                                                                </p>
                                                                <div className="progress" style={{height: '7px'}}>
                                                                    <div className="progress-bar bg-warning" style={{width: `${GetPercentage(admin.statMonth.totals.total, admin.statMonth.totals.total_cash)}%`}}></div>
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                <p className="mb-3">
                                                                    <img src={require('../../img/icons/credit-card.png')} className='me-2' width={20} alt="icon"/>
                                                                    Безналичные 
                                                                    ({GetPercentage(admin.statMonth.totals.total, admin.statMonth.totals.total_card)}%)
                                                                    <span className="float-end">
                                                                        {admin.statMonth.totals.total_card} ₽
                                                                    </span>
                                                                </p>
                                                                <div className="progress" style={{height: '7px'}}>
                                                                    <div className="progress-bar bg-danger" style={{width: `${GetPercentage(admin.statMonth.totals.total, admin.statMonth.totals.total_card)}%`}}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className='col-12 text-center mb-4'>
                                        <Loader/>
                                    </div>
                                )}
                            </div>
                        </div>
                        {admin.activeShops ? (
                            <div className="col-12 col-md-5 d-flex align-items-stretch mt-4 mt-md-0">
                                <div className="card w-100 rounded-4 mb-0">
                                    <div className="card-body shops p-md-4 p-3">
                                        <div className="d-flex align-items-center mb-2">
                                            <div className="d-flex align-items-center flex-grow-0-5 w-50">
                                                <h5 className="mb-0 ms-3">Заведение</h5>
                                            </div>
                                            <div className="flex-grow-0-5">
                                                <h6 className="mb-0">Id</h6>
                                            </div>
                                            <div className="me-3">
                                                <h6 className="mb-0">Статус</h6>
                                            </div>
                                        </div> 
                                        <hr/>
                                        <div className="d-flex flex-column justify-content-between gap-3 mt-3 mt-md-2 card-stores">
                                            {admin.activeShops && admin.activeShops.map((item, index) => (
                                                <div className="d-flex align-items-center gap-4" key={index}>
                                                    <div className="d-flex align-items-center flex-grow-0-5 w-50">
                                                        <p className="mb-0 ms-3">{item.store_name}</p>
                                                    </div>
                                                    <div className="flex-grow-0-5">
                                                        <p className="mb-0">{item.id}</p>
                                                    </div>
                                                    <div className="me-3">
                                                        <p className="mb-0 data-attributes">
                                                            <span className="badge bg-grd-success">Online</span>
                                                        </p>
                                                    </div>
                                                </div> 
                                            ))}
                                            {notActiveShops && notActiveShops.map((item, index) => (
                                                <div className="d-flex align-items-center gap-4" key={index}>
                                                    <div className="d-flex align-items-center flex-grow-0-5 w-50">
                                                        <p className="mb-0 ms-3">{item.store_name}</p>
                                                    </div>
                                                    <div className="flex-grow-0-5">
                                                        <p className="mb-0">{item.id}</p>
                                                    </div>
                                                    <div className="me-3">
                                                        <p className="mb-0 data-attributes">
                                                            <span className="badge bg-grd-royal">Offline</span>
                                                        </p>
                                                    </div>
                                                </div> 
                                            ))}
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        ) : (
                            <div className='col-12 col-md-5 text-center mt-5'>
                                <Loader/>
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4 mt-4">
                            <div className="card w-100 h-100 rounded-4 p-md-2 p-1 mb-0">
                                {admin.topUsers ? (
                                    <div className="card-body">
                                        <div className="d-flex align-items-start justify-content-between">
                                            <div className="">
                                                <h5 className="mb-4">Топ курьеров 🥇</h5>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column gap-4">
                                            {admin.topUsers.map((item, index) => (
                                                <div className="d-flex align-items-center gap-3 mt-3" key={index}>
                                                    <img src={require('../../img/logo/logo-icon-2.png')} width="50" className="rounded-circle d-none d-md-block" alt=""/>
                                                    <div className="flex-grow-1">
                                                        <h6 className="mb-0">
                                                            {item.name}
                                                            <img src={require(`../../img/icons/top-${index}.png`)} width="30" className="ms-2" alt=""/>
                                                        </h6>
                                                    </div>
                                                    <div className="flex-grow-1 text-end">
                                                        <p className="mb-0">
                                                            Сумма: 
                                                            <strong className='text-success'> {item.total_sum} ₽</strong>
                                                        </p>
                                                        <p className="mb-0">Заказы: {item.order_count} шт.</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div className='text-center'>
                                        <Loader/>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-12 col-md-8 mt-4">
                            <div className="card rounded-4 p-md-2 p-1 h-100 mb-0">
                                {admin.topShops ? (
                                    <div className="card-body">
                                        <div className="d-flex align-items-start justify-content-between">
                                            <div className="">
                                                <h5 className="mb-3">Топ заведений 🏆</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {admin.topShops.map((item, index) => (
                                                <div className={`${index === 0 ? 'col-12' : 'col-md-6 col-12 text-center'} mt-4`} key={index}>
                                                    <div className="card rounded-4 mb-0 border">
                                                        <div className="card-body">
                                                            <div className={`${index === 0 && 'text-center mb-2'}`}>
                                                                {index === 0 ? (
                                                                    <>
                                                                        <h3 style={{minHeight: '50px'}} className='mb-2 media-fs-25'>
                                                                            <img src={require(`../../img/icons/kubok-${index}.png`)} width="40" className="me-2" alt=""/>
                                                                            {item.store}
                                                                        </h3>
                                                                        <div className="row">
                                                                            <div className="col-md-6 col-12 ms-md-0">
                                                                                <h5 className="mb-0 fw-light">
                                                                                    Сумма: 
                                                                                    <strong className='fw-bold text-success'> {item.total_sum} ₽</strong>
                                                                                </h5>
                                                                            </div>
                                                                            <div className="col-md-6 col-12 ms-me-0">
                                                                                <h5 className="mb-0 fw-light">
                                                                                    Заказы: {item.order_count} шт.
                                                                                </h5>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <h6 style={{minHeight: '45px'}} className='mb-0 media-fs-23'>
                                                                            <img src={require(`../../img/icons/kubok-${index}.png`)} width="30" className="me-2 mb-1" alt=""/>
                                                                            {item.store}
                                                                        </h6>
                                                                        <div className="d-md-flex mt-3 ms-3 ms-md-0">
                                                                            <h6 className="mb-0 fw-light ms-md-1 ms-0">
                                                                                Сумма: 
                                                                                <strong className='fw-bold text-success'> {item.total_sum} ₽</strong>
                                                                            </h6>
                                                                            <h6 className="mb-0 fw-light ms-md-5 ms-0">
                                                                                Заказы: {item.order_count} шт.
                                                                            </h6>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div className='text-center'>
                                        <Loader/>
                                    </div>  
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};    